import React from "react"
import styled from "styled-components"
import headerImage from "../../images/index_bg.jpg"
import NextSectionButton, {Link} from "../common/sections/next-section-button";
import PageSection from "../common/sections/page-section";
import EmphasizedSection from "../common/sections/emphasized-section";
import fiduApp from "../../images/fidu-frame.png"
import fiduAppWithLogo from "../../images/fidu-frame-logo.png"
import {COLORS, device} from "../../styles/constants";

const HeroContainer = styled.div`
    display: flex;
    flex-direction: column;
    padding: 4rem 1rem 0rem 1rem;
    max-width: 960px;
    width: 100%;
    margin: 60px auto 0 auto;
    justify-content: space-around;
    flex: 1;
`

const CenteredContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    width: 100%;
    padding: 2rem;
    max-width: 960px;
    margin: 0 auto;
    flex: 1;
`

const FeatureContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    width: 100%;
    flex: 1;
    
    .image {
        flex: 1;
        margin-top: 50px;
    }
    
    .text {
        flex: 1;
        padding-right: 2rem;
    }
    
    .text > .button {
        display: none;
    }
    
    @media ${device.tablet} { 
         flex-direction: row;
        .image {
            flex: 0.5;
            margin-top: 0;
        }
        .text {
            flex: 0.5;
        }
        .text > .button {
            display: block;
        }
        .button {
            display: none;
        }
    }
`

const StyledImage = styled.img`
    height: 600px;
`

const Hero = () => (
    <PageSection backgroundImage={headerImage} first={true}>
        <HeroContainer>
            <div>
                <h1 style={{
                    textAlign: "left",
                    maxWidth: 440,
                    fontWeight: "bold",
                    fontSize: "3.5rem"
                }}>Hi moms!</h1>
                <p style={{
                    textAlign: "left",
                    maxWidth: 440,
                    fontWeight: 500,
                    fontStyle: "italic",
                    fontSize: "1.4rem",
                    lineHeight: "1.9"
                }}>
                    it's your pregnancy.<br/>
                    you decide.<br/>
                    period.
                </p>
            </div>
            <div style={{
                maxWidth: 600,
            }}>
                <p style={{
                    textAlign: "left",
                    fontWeight: 500,
                    fontSize: "1.3rem"
                }}>FIDU - your personal maternity record.</p>
                <p style={{
                    textAlign: "left",
                    fontWeight: 500,
                    fontSize: "0.8rem"
                }}>With the FIDU maternity record on your side you are able
                    to focus on what's important, your baby. Stay informed
                    about your own and your baby's health, stay connected with
                    your midwife and gynaecologist at any time or share your
                    record with your loved ones. <br/><br/>
                    <i>You are in control because its your pregnancy.</i>
                </p>
                <NextSectionButton target="page-1">show me more</NextSectionButton>
            </div>
        </HeroContainer>
    </PageSection>
)

const Content = () => (
    <>
        <PageSection id="page-1">
            <EmphasizedSection
                text="We truly believe that you deserve an amazing pregnancy experience, no matter what circumstances."
            />
            <CenteredContainer>
                <FeatureContainer>
                    <div className="text">
                        <p style={{
                            textAlign: "left",
                            fontWeight: 440,
                            fontSize: "2rem",
                            color: `${COLORS.purple}`
                        }}>Everything in one place.</p>
                        <p style={{
                            textAlign: "left",
                            fontWeight: 440,
                            fontSize: "1.4rem",
                            color: `${COLORS.purple}`
                        }}><i>Everywhere you go.</i></p>
                        <br/>
                        <p style={{
                            textAlign: "left",
                            fontWeight: "lighter",
                            lineHeight: "1.8rem"
                        }}>With FIDU you have your personal pregnancy record always with you.
                            Don't worry about forgetting important documents or test results.
                            All your pregnancy related health data is just one click away,
                            everywhere you go, at any time. Bye paperwork. Hello freedom.<br/><br/>
                            <i>You deserve to be able to focus on what's important.</i>
                        </p>
                        <div className="button">
                            <NextSectionButton target="page-2">show me more</NextSectionButton>
                        </div>
                    </div>
                    <div className="image">
                        <StyledImage src={fiduApp}/>
                    </div>
                    <div className="button">
                        <NextSectionButton target="page-2">show me more</NextSectionButton>
                    </div>
                </FeatureContainer>
            </CenteredContainer>
        </PageSection>
        <PageSection id="page-2">
            <EmphasizedSection
                text="We want you to feel guided and in the hands of people you trust."
            />
            <CenteredContainer>
                <FeatureContainer>
                    <div className="text">
                        <p style={{
                            textAlign: "left",
                            fontWeight: 440,
                            fontSize: "2rem",
                            color: `${COLORS.purple}`
                        }}>Stay connected.</p>
                        <p style={{
                            textAlign: "left",
                            fontWeight: 440,
                            fontSize: "1.4rem",
                            color: `${COLORS.purple}`
                        }}><i>Feel safe.</i></p>
                        <br/>
                        <p style={{
                            textAlign: "left",
                            fontWeight: "lighter",
                            lineHeight: "1.8rem"
                        }}>Use FIDU together with your trusted midwife or gynaecologist.
                            Like this you stay connected with your healthcare professionals at all time.
                            If you have a question, just ask. If you feel something is not okay, just reach out.
                            To make your pregnancy a relaxed and amazing experience, its important that all your
                            questions are answered.<br/><br/>
                            <i>You deserve that.</i>
                        </p>
                        <div className="button">
                            <NextSectionButton target="page-3">show me more</NextSectionButton>
                        </div>
                    </div>
                    <div className="image">
                        <StyledImage src={fiduAppWithLogo}/>
                    </div>
                    <div className="button">
                        <NextSectionButton target="page-3">show me more</NextSectionButton>
                    </div>
                </FeatureContainer>
            </CenteredContainer>
        </PageSection>
        <PageSection id="page-3" last={true}>
            <EmphasizedSection
                text="We want you to be able to focus on what's important. Your pregnancy."
            />
            <CenteredContainer>
                <FeatureContainer>
                    <div className="text">
                        <p style={{
                            textAlign: "left",
                            fontWeight: 440,
                            fontSize: "2rem",
                            color: `${COLORS.purple}`
                        }}>Your pregnancy.</p>
                        <p style={{
                            textAlign: "left",
                            fontWeight: 440,
                            fontSize: "1.4rem",
                            color: `${COLORS.purple}`
                        }}><i>Your data.</i></p>
                        <br/>
                        <p style={{
                            textAlign: "left",
                            fontWeight: "lighter",
                            lineHeight: "1.8rem"
                        }}>To keep your pregnancy data safe, is our highest priority!
                            We incorporated data security at all levels of our business,
                            right from the start. All your data is safely stored and encrypted
                            using state-of-the-art encryption technology. Nobody can read your
                            health data without your permission. Together with our data
                            security experts, we continuously reassess our security standards.<br/><br/>
                            <i>This is extremely important to us, honestly.</i>
                        </p>
                        <p style={{
                            textAlign: "center",
                            fontWeight: 440,
                            color: `${COLORS.purple}`,
                            fontSize: "2rem",
                            alignSelf: "center",
                            paddingTop: 50,
                            paddingBottom: 20
                        }}>Interested? Get in touch!</p>
                        <div style={{
                            textAlign: "center",
                            fontWeight: 440,
                            color: `${COLORS.purple}`,
                            alignSelf: "center",
                            paddingBottom: 50
                        }}><Link href="mailto:office@fidu.health">office@fidu.health</Link></div>
                    </div>
                    <div className="image">
                        <StyledImage src={fiduAppWithLogo}/>
                    </div>
                </FeatureContainer>
            </CenteredContainer>
        </PageSection>
    </>

)

const IndexContent = () => {
    return (<>
        <Hero />
        <Content />
    </>);
}

export default IndexContent;