import React from "react"

import Layout from "../components/common/layout/layout"
import SEO from "../components/seo"
import IndexContent from "../components/index/index-content";

const IndexPage = () => (
    <Layout showFooter={true}>
        <SEO title="Hi Moms!" />
        <IndexContent />
    </Layout>
)

export default IndexPage
